import {API_ENDPOINT} from "./common.js";
const FETCH_CLIPPING_QUERY = `
query fetchClipping($id: Int!) {
  clipping(id: $id) {
    id
    bookID
    title
    content
    createdAt
    pageAt
    visible
    reactions {
      id
      symbol
      creator {
        id
        avatar
        name
      }
      createdAt
    }
    creator {
      id
      name
      avatar
    }
  }
}
`;
export function fetchClipping(cid) {
  return fetch(API_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      operationName: "fetchClipping",
      query: FETCH_CLIPPING_QUERY,
      variables: {
        id: cid
      }
    })
  }).then((res) => res.json()).then((res) => res.data.clipping);
}
